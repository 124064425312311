import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../config/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Box, Image, Text, Button, Flex, Heading, Spacer, Stack } from '@chakra-ui/react';
import Navbar from './Navbar';
import { CartContext } from './CartContext';
import { PROTECTED } from '../config/routes';

export default function Product() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const { addToCart } = useContext(CartContext);

  useEffect(() => {
    const fetchData = async () => {
      const productDoc = doc(db, 'products', id);
      const productSnapshot = await getDoc(productDoc);
      if (productSnapshot.exists()) {
        setProduct({ id: productSnapshot.id, ...productSnapshot.data() });
      } else {
        console.error('Product does not exist');
      }
    };

    fetchData();
  }, [id]);

  if (!product) {
    return 'Loading...';
  }

  return (
    <Stack direction={{ base: 'column', md: 'row' }}>
      <Navbar />
      <Box w={{ base: '100%', md: '50%' }} p="5" pt="8" mt={40}>
        <Image src={product.imageFile} alt={product.name} boxSize="600px" objectFit="cover" mx="auto" />
      </Box>
      <Box w={{ base: '100%', md: '50%' }} p="5" mt={40}>
        <Heading as="h2" size="xl">
          {product.name}
        </Heading>
        <Text fontSize="lg" color="gray.500" my={4}>
          {product.description}
        </Text>
        <Text fontSize="xl" fontWeight="bold" mb={5}>
          ${product.price}
        </Text>
        <Button
          colorScheme="black"
          variant="outline"
          w="100%"
          onClick={() => {
            addToCart(product);
            navigate(`${PROTECTED}/cart/:id`);
          }}
        >
          Add to cart
        </Button>
      </Box>
    </Stack>
  );
}
