import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../hooks/auth';
import { LOGIN } from '../config/routes';
import Navbar from './Navbar';
import HeroBanner from './HeroBanner';
import { Box, Flex } from '@chakra-ui/react';

export default function Layout() {
  const {pathname} = useLocation();
  const navigate = useNavigate()
  const {user, isLoading} = useAuth();


  useEffect(() => {
    if (!isLoading && pathname.startsWith("/protected") && !user){
      navigate(LOGIN)
    }
  }, [pathname,user,isLoading]);

  if (isLoading) return "Loading user ...."
  
  return (
    <>
     <Outlet />
</>
    
  )
}
