import React from 'react'
import Navbar from './Navbar'

import { Flex } from '@chakra-ui/react'
import HeroBanner from './HeroBanner'
import LoggedNavBar from './LoggedNavBar'
export default function Dashboard() {
  return (
 
      
      <Flex> 
    
     <LoggedNavBar />
     <HeroBanner />
   </Flex>
  
   
  )
}
