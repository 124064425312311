import { useState } from 'react';
import {
  Flex,
  useBreakpointValue,
  Link,
  Input,
  InputGroup,
  InputLeftElement,
  Avatar,
  Text,
  Button,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useLogout } from '../hooks/auth';
import { DASHBOARD, LANDING, LOGIN, PRODUCTLIST, PROTECTED, REGISTER, SELLERFORM } from '../config/routes';
import { SearchIcon } from '@chakra-ui/icons';
import { IoMdCart, IoMdAddCircle, IoMdMenu } from 'react-icons/io';

export default function LoggedNavBar() {
  const { logout, isLoading } = useLogout();
  const btnVariant = useBreakpointValue({ base: 'icon', md: 'text' });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const renderDesktopMenu = () => (
    <Flex align="center">
      <InputGroup maxW="500px" ml={4} mr={6}>
        <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
        <Input type="text" placeholder="Search for items" />
      </InputGroup>

      <Link as={RouterLink} to={`${PROTECTED}/cart/:id`}>
        <IoMdCart size={30} color="white" />
      </Link>

      <Button
        as={RouterLink}
        to={SELLERFORM}
        ml={2}
        colorScheme="whiteAlpha"
        variant="ghost"
        leftIcon={<IoMdAddCircle size={30} />}
        size="sm"
        display={btnVariant}
      ></Button>
    </Flex>
  );

  const renderMobileMenu = () => (
    <Flex align="center">
       <IconButton
        color="black"
        bg={"transparent"}
        aria-label="Open Menu"
        icon={<IoMdMenu />}
        display={{ base: 'flex', md: 'none' }}
        onClick={handleDrawerOpen}
        mr={2}
      />
      
      <InputGroup maxW="500px" ml={4} mr={2}>
        <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
        <Input type="text" placeholder="Search for items" />
      </InputGroup>
      <Button
        as={RouterLink}
        to={SELLERFORM}
        ml={2}
        colorScheme="black"
        bg={"transparent"}
        leftIcon={<IoMdAddCircle size={30} />}
        size="sm"
        display={btnVariant}
      ></Button>
  
      
  
     
    </Flex>
  );
  

  return (
    <Flex shadow="sm" pos="fixed" width="full" bg="#581f18" height="20" zIndex="3" justify="center">
      <Flex px={2} w="full" align="center" maxW="1200px" justifyContent="space-between">
        <Flex align="center">
          <Text color="whiteAlpha.800" fontSize="xl" fontWeight="bold" mr={25} ml={-2} fontFamily="cursive">
            Christian's Store
          </Text>

          {isMobile ? null : (
            <>
              <Link color="white" as={RouterLink} to={LANDING} fontWeight="bold" ml={10} mr={10}>
                Home
              </Link>
              <Link color="white" as={RouterLink} to={PRODUCTLIST} fontWeight="bold" mr={10}>
                Products
              </Link>
              <Link color="white" as={RouterLink} to={LOGIN} fontWeight="bold" mr={10}>
                Login
              </Link>
              <Link color="white" as={RouterLink} to={REGISTER} fontWeight="bold" mr={35}>
                Signup
              </Link>
            </>
          )}
        </Flex>

        {isMobile ? renderMobileMenu() : renderDesktopMenu()}
      </Flex>

      <Drawer placement="left" onClose={handleDrawerClose} isOpen={isDrawerOpen}>
        <DrawerOverlay>
          <DrawerContent bg="#581f18">
            <DrawerCloseButton color="white" mt={2} />
            <DrawerHeader color="white">Menu</DrawerHeader>
            <DrawerBody>
              <VStack spacing={4} align="start" p={4}>
                <Link color="white" as={RouterLink} to={LANDING} fontWeight="bold">
                  Home
                </Link>
                <Link color="white" as={RouterLink} to={PRODUCTLIST} fontWeight="bold">
                  Products
                </Link>
                <Link color="white" as={RouterLink} to={LOGIN} fontWeight="bold">
                  Login
                </Link>
                <Link color="white" as={RouterLink} to={REGISTER} fontWeight="bold">
                  Signup
                </Link>
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Flex>
  );
}
