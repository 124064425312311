import React from 'react'
import Navbar from './Navbar'
import HeroBanner from './HeroBanner'
import { Flex } from '@chakra-ui/react'
export default function LandingPage() {
  return (
   <Flex> 
    
     <Navbar />
     <HeroBanner />
   </Flex>
   
  
  )
}
