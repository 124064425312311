import { Box, Button, Text, VStack, Image, Flex, Spacer, Heading, IconButton, Stack, Center } from '@chakra-ui/react';
import { CartContext } from './CartContext';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { useContext } from 'react';

export default function Cart() {
  const { cart, removeFromCart } = useContext(CartContext);

  const getTotalPrice = () => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  return (
    <Center>
      <VStack spacing={4} p={4} w="800px">
        {cart.map((item) => (
          <Box key={item.id} w="full" borderWidth={1} borderRadius="md" p={4}>
            <Flex>
              <Box flex={1}>
                <Image boxSize="100px" objectFit="cover" src={item.imageFile} alt={item.name} />
              </Box>
              <Stack flex={2} spacing={4} ml={4}>
                <Text fontSize="xl">{item.name}</Text>
                <Flex align="center">
                  <IconButton aria-label="Decrease quantity" icon={<AiOutlineMinus />} /> 
                  <Text mx={2}>{item.quantity}</Text>
                  <IconButton aria-label="Increase quantity" icon={<AiOutlinePlus />} />
                </Flex>
                <Text fontSize="lg">${item.price}</Text>
                <Button colorScheme="red" onClick={() => removeFromCart(item.id)}>Remove from cart</Button>
              </Stack>
            </Flex>
          </Box>
        ))}
        <Box w="full" borderWidth={1} borderRadius="md" p={4}>
          <Heading size="lg">Subtotal: ${getTotalPrice()}</Heading>
        </Box>
      </VStack>
    </Center>
  );
}
