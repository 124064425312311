import {getFirestore} from 'firebase/firestore'
import {getAuth} from 'firebase/auth'
import {getStorage} from 'firebase/storage';
import {initializeApp} from 'firebase/app'


const firebaseConfig = {

    apiKey: "AIzaSyC-Ma0OyV-BhRjKltxWHlpTDl-V1RKdxgQ",
  
    authDomain: "ecommerce-3ec7d.firebaseapp.com",
  
    projectId: "ecommerce-3ec7d",
  
    storageBucket: "ecommerce-3ec7d.appspot.com",
  
    messagingSenderId: "409319732743",
  
    appId: "1:409319732743:web:cbc43e29b05f8e99cb5ca2"
  
  };
  
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)
export const auth = getAuth(app)
export const storage = getStorage(app)  