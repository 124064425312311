import { Box, Heading, Text, Button } from "@chakra-ui/react"
import { Link } from "react-router-dom";
import { PRODUCTLIST } from "../config/routes";

const HeroBanner = () => {
  return (
    <Box
      w={"full"}
      h={"100vh"} // adjust this to control the height of the banner
      bgImage={"url('/StockImages/headerimage.jpg')"} // if you have a background image
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      textAlign={"center"}
      color={"white"} // text color
    >
      <Heading size={"2xl"} mb={"2"}>
        Welcome to our store
      </Heading>
      <Text fontSize={"xl"} mb={"4"}>
        Discover our latest collection
      </Text>
      <Button colorScheme={'blackAlpha'}
      as={Link}
      to={PRODUCTLIST}
      
      
      >
        Shop now
        
      </Button>
    </Box>
  )
}

export default HeroBanner;
