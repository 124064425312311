import React from 'react';
import Navbar from './Navbar';
import { Box, Button, Heading, HStack, Textarea, Input, FormLabel, FormControl } from '@chakra-ui/react';
import { useSellProduct } from '../hooks/seller';
import { useAuth } from '../hooks/auth';
import { useForm } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';

export default function SellerForm() {
  const { register, handleSubmit, reset, watch } = useForm();
  const { addProduct, isLoading: addingProduct, uploadImage } = useSellProduct();
  const { user, isLoading: authLoading } = useAuth();

  const imageFile = watch('image');

  async function handleAddProduct(data) {
    try {
      const imageUrl = await uploadImage(data.image[0]);

      addProduct({
        name: data.name,
        description: data.description,
        price: data.price,
        imageFile: imageUrl,
      });

      reset();
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Box maxW="600px" mx="auto" py="10">
      <form onSubmit={handleSubmit(handleAddProduct)}>
        <HStack justify="space-between">
          <Heading size="lg">New Product</Heading>
          <Button
            colorScheme="blackAlpha"
            type="submit"
            isLoading={authLoading || addingProduct}
            loadingText="Loading"
          >
            Post
          </Button>
        </HStack>
  
        <FormControl id="name" mt={4}>
          <FormLabel>Product Name</FormLabel>
          <Input {...register('name')} />
        </FormControl>
  
        <Textarea
          as={TextareaAutosize}
          resize="none"
          mt="5"
          placeholder="Product Description..."
          minRows={3}
          {...register('description', { required: true })}
        />
  
        <FormControl id="price" mt={4}>
          <FormLabel>Product Price</FormLabel>
          <Input {...register('price')} type="number" />
        </FormControl>
  
        <Button colorScheme="blackAlpha" onClick={() => document.getElementById('fileInput').click()} mt="4">
          Upload Image
        </Button>
  
        <Input id="fileInput" type="file" {...register('image')} style={{ display: 'none' }} />
  
      </form>
    </Box>
  );
  
}
