import React from 'react'
import {Center,Box, Heading, FormLabel, Input, FormControl, FormErrorMessage, Button,Link,Text} from '@chakra-ui/react'
import {Link as RouterLink, redirect} from 'react-router-dom'
import { useRegister } from '../hooks/auth'
import { emailValidate,passwordValidate,usernameValidate } from '../utils/form-validate'
import { useForm } from 'react-hook-form'
import { DASHBOARD, LOGIN } from '../config/routes'
export default function Register() {
  
  const {register: signup, isLoading} = useRegister();
  const {
    register,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm()

  console.log(errors);



  async function handleRegister(data){
    signup({
        username: data.username,
        email: data.email,
        password: data.password,
        redirectTo: DASHBOARD,
    });
  }
    return (
        
        <Box
        w={"full"}
        h={"full"}
        bgImage={"url('/StockImages/headerimage.jpg')"} // replace with the path to your image
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        textAlign={"center"}
    ><Center w= "100%" h="100vh">
        <Box mx={"1"} maxW={"md"} p={"9"} borderWidth={"1px"} borderRadius={"lg"} bg={"rgba(0,0,0,0.5)"}>
            <Heading mb={"4"} size={"lg"} textAlign={"center"} color={"white"}>
                Register
            </Heading>
    
             <form onSubmit={handleSubmit(handleRegister)}>
                <FormControl isInvalid = {errors.username} py = "2">
                    <FormLabel>Username</FormLabel>
                        <Input 
                        placeholder='username' 
                        {...register('username',usernameValidate)}/>
                        <FormErrorMessage>
                          {errors.username && errors.username.message}
                          </FormErrorMessage>
                   
                </FormControl>
                <FormControl isInvalid = {errors.email} py = "2">
                    <FormLabel>
                        <Input type = "email" placeholder='user@email.com' {...register('email',emailValidate)}/>
                        <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
                    </FormLabel>
                </FormControl>
                <FormControl isInvalid = {errors.password} py = "2">
                    <FormLabel>
                        <Input type = "password" placeholder='password123' {...register('password',passwordValidate)}/>
                        <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
                    </FormLabel>
                </FormControl>
                <Button  mt="4" 
                type='submit' 
                colorScheme="blackAlpha"
                size= "md" w = "full" 
      
                loadingText = "Signing UP">
                  Register </Button> 
               
             </form>
            <Text fontSize= "xlg" align= "center" mt = "6" textColor={'whiteAlpha.900'}> Already have an account? {" "} 
            <Link 
            as = {RouterLink}
             to={LOGIN} 
            color = "blackAlpha" 
            fontWeight="medium"
            textDecor="underline" 
            _hover={{background:"gray.800"}} >
                
                Login</Link> {" "}
                instead!
            </Text>
             
         </Box>
       </Center>
       </Box>
        
  )
}
