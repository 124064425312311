
import { db } from "../config/firebase";
import { getDownloadURL, uploadBytesResumable,ref} from "firebase/storage";
import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import {uuidv4} from '@firebase/util'
import {
    arrayRemove,
    arrayUnion,
    collection,
    deleteDoc,
    doc,
    getDocs,
    orderBy,
    query,
    setDoc,
    updateDoc,
    where,
  } from "firebase/firestore";
  import { storage } from '../config/firebase';
import {useCollectionData, useDocumentData} from 'react-firebase-hooks/firestore'  

export function useSellProduct (){
    const [isLoading, setLoading] = useState(false)
    const toast = useToast();

    async function uploadImage(file) {
        return new Promise((resolve,reject) => {
            const storageRef = ref(storage,`products/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef,file);

            uploadTask.on('state_changed',
            (snapshot) => {

            }, (error) => {
                reject(error);
            },

            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    resolve(downloadURL);
                });
            }
               
            );
        })
    }
    
    async function addProduct(product) {
        try {
          setLoading(true);
          const id = uuidv4();
      
          const { price, description, name } = product;
      
          let imageUrl;
          if (product.image) {
            imageUrl = await uploadImage(product.image);
          }
      
          const productData = {
            ...product,
            id,
            date: Date.now(),
            name,
            price,
            description,
          };
      
          if (imageUrl) {
            productData.image = imageUrl;
          }
      
          await setDoc(doc(db, "products", id), productData);
      
          toast({
            title: "Product added successfully",
            status: "success",
            isClosable: true,
            position: "top",
            duration: 5000,
          });
        } catch (error) {
          toast({
            title: "Failed to add product",
            description: error.message,
            status: "error",
            isClosable: true,
            position: "top",
            duration: 5000,
          });
        } finally {
          setLoading(false);
        }
      }
      

    return {addProduct, isLoading, uploadImage}; 
}

export function useProduct(id){
    const q = doc(db,"products",id);
    const [product, isLoading] = useDocumentData(q)
    return {product, isLoading};
}


export function useProducts(uid = null){
    const q = uid
     ? query(
        collection(db,"products"),
        where("uid", "==", uid)
     )
     : query(collection(db,"products"))
     const [products, isLoading, error] = useCollectionData(q);
     if (error) throw error;
     return {products,isLoading}
}
