import React from 'react'
import { useLogin } from '../hooks/auth'
import {useForm} from 'react-hook-form'
import {Center,Box, Heading, FormLabel, Input, FormControl, FormErrorMessage, Button, Link, Text} from '@chakra-ui/react'
import { DASHBOARD } from '../config/routes';
import { emailValidate,passwordValidate } from '../utils/form-validate';
import { Link as RouterLink } from 'react-router-dom';
import { REGISTER } from '../config/routes';

export default function Login() {

    const {login, isLoading} = useLogin();
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm();


    async function handlelogin(data) {
        login({
            email:data.email,
            password: data.password,
            redirectTo:DASHBOARD,
        })
    }

    return (
    <Box
        w={"full"}
        h={"full"}
        bgImage={"url('/StockImages/headerimage.jpg')"} // replace with the path to your image
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        textAlign={"center"}
    >
       <Center w = "100%" h={"100vh"}>
        
        <Box mx={"1"} maxW={"md"} p={"9"} borderWidth={"1px"} borderRadius={"lg"} bg={"rgba(0,0,0,0.5)"}>
            <Heading mb={"4"} size={"lg"} textAlign={"center"} color={"white"}>
                Log in
            </Heading>

            <form onSubmit={handleSubmit(handlelogin)}>
                <FormControl isInvalid = {errors.email} py = "2">
                    <FormLabel color={"white"}>Email</FormLabel>
                    <Input
                        type='email'
                        placeholder='user@email.com'
                        {...register("email",emailValidate)} 
                        color={'white'}
                        />
                    <FormErrorMessage color={"red.300"}>
                        {errors.email && errors.email.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.password} py="2">
              <FormLabel color={"white"}>Password</FormLabel>
              <Input
                type="password"
                placeholder="password123"
                color={'white'}
                {...register("password", passwordValidate)}
              />
              <FormErrorMessage color={"red.300"}>
                {errors.password && errors.password.message}
              </FormErrorMessage>
            </FormControl>
            <Button 
            mt={"4"}
            type='submit'
            colorScheme='blackAlpha'
            size={"md"}
            w={"full"}
            isLoading = {isLoading}
            loadingText = "Logging in"
            >
                Log In
            </Button>
            </form>
            <Text fontSize="xlg" align="center" mt="6" color={"white"}>
            Don't have an account?{" "}
            <Link
              as={RouterLink}
              to={REGISTER}
              color="blackAlpha"
              fontWeight="medium"
              textDecor="underline"
              _hover={{ background: "gray.100" }}
            >
              Register
            </Link>{" "}
            instead!
          </Text>
        </Box>
   </Center>
   </Box>
  )
}
