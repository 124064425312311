import React, { useEffect, useState } from 'react';
import { db } from '../config/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { Box, Image, Text, Badge, Flex, AspectRatio, useBreakpointValue } from '@chakra-ui/react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';

export default function Products() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const productsCollection = collection(db, 'products');
      const productsSnapshot = await getDocs(productsCollection);
      const productsData = productsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setProducts(productsData);
    };

    fetchData();
  }, []);

  const slidesToShow = useBreakpointValue({ base: 1, sm: 2, md: 3 });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
  };

  return (
    <Flex direction="column">
      <Navbar />
      <Slider {...settings}>
        {products.map((product) => {
          return (
            <Box key={product.id} p="5" borderWidth="1px" borderRadius="lg" maxW="sm" overflow="hidden" mt={100}>
              <AspectRatio ratio={0.75}>
                <Link to={`/product/${product.id}`}>
                  <Image
                    src={product.imageFile}
                    alt={product.name}
                    onError={(e) => {
                      console.error(`Failed to load image: ${product.imageFile}`);
                      e.target.style.display = 'none'; // hide the broken image icon in the UI
                    }}
                  />
                </Link>
              </AspectRatio>

              <Box p="6">
                <Box d="flex" alignItems="baseline">
                  <Badge borderRadius="full" px="2" colorScheme="teal">
                    New
                  </Badge>
                </Box>
                <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight">
                  {product.name}
                </Box>
                <Box>
                  ${product.price}
                  <Box as="span" color="gray.600" fontSize="sm">
                    / item
                  </Box>
                </Box>
                <Box>{product.description}</Box>
              </Box>
            </Box>
          );
        })}
      </Slider>
    </Flex>
  );
}
