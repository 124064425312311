import {createBrowserRouter} from 'react-router-dom'
import LandingPage from '../components/LandingPage';
import Login from '../auth/Login';
import Register from '../auth/Register';
import Reviews from '../components/Reviews';
import SellerForm from '../components/SellerForm';
import ShoppingCart from '../components/ShoppingCart';
import ProductPage from '../components/ProductPage';
import ProductList from '../components/Products';
import Layout from '../components/Layout';
import Dashboard from '../components/Dashboard';
import SellerProfile from '../components/SellerProfile';
import Product from '../components/Product';
import Cart from '../components/Cart';

export const LANDING = "/"
export const PRODUCTLIST = "/products"
export const PROTECTED = "/protected";
export const SELLERPROFILE = "/protected/sellerprofile"
export const REVIEWS = "/protected/reviews/:id"
export const DASHBOARD = "/protected/dashboard"
export const SELLERFORM = "/protected/sellerform/:id"
export const CART = "/protected/cart/:id"
export const REGISTER = "/register"
export const LOGIN = "/login"
export const PRODUCT = "/product/:id"


export const router = createBrowserRouter([
    {path: LANDING, element: <LandingPage />},
    {path:LOGIN, element:<Login />},
    {path:REGISTER, element: <Register />},
    {path:PRODUCTLIST, element: <ProductList />},
    {path:PRODUCT, element: <Product />},
    {
        path: PROTECTED,
        element: <Layout />,
        children: [
            {
                path:DASHBOARD,
                element: <Dashboard/>
            },

            {
                path:SELLERFORM,
                element:<SellerForm />
            },

            {
                path: CART,
                element:<Cart />
            },

            {
                path:SELLERPROFILE,
                element: <SellerProfile />
            },

        

        ]
    }
])