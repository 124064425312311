import React from "react";
import {ChakraProvider} from '@chakra-ui/react'
import { RouterProvider } from "react-router-dom";
import { router } from './config/routes';
import { CartProvider } from "./components/CartContext";

function App() {
  return (
   <ChakraProvider>
        <CartProvider> 
          <RouterProvider router = {router} />
        </CartProvider>
     
   </ChakraProvider>
  );
}

export default App;
